var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chat-sidebar" },
    [
      _vm.activeChat
        ? _c(
            "vue-perfect-scrollbar",
            { attrs: { settings: _vm.perfectScrollbarSettings } },
            [
              _c("div", [
                _c("p", [
                  _c("b", [_vm._v(_vm._s(_vm.$t("Cliente")) + ":")]),
                  _vm._v(" " + _vm._s(_vm.activeChat.customer.name) + " "),
                ]),
                _c("p", [
                  _c("b", [_vm._v(_vm._s(_vm.$t("Id do Pedido")) + ":")]),
                  _vm._v(" " + _vm._s(_vm.activeChat.id) + " "),
                ]),
                _c("p", [
                  _c("b", [_vm._v(_vm._s(_vm.$t("Realizado em")) + ":")]),
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("datetime")(_vm.activeChat.issueDate)) +
                      " "
                  ),
                ]),
                _c("p", [
                  _c("b", [_vm._v(_vm._s(_vm.$t("Agendado para")) + ":")]),
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("datetime")(_vm.activeChat.deliveryDate)) +
                      " "
                  ),
                ]),
                _c("p", [
                  _c("b", [_vm._v(_vm._s(_vm.$t("Valor")) + ":")]),
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("currency")(_vm.activeChat.netValue)) +
                      " "
                  ),
                ]),
              ]),
              _c("div", [
                _c("h5", [_vm._v("Produtos")]),
                _c(
                  "ul",
                  _vm._l(_vm.localOrderItems, function (orderItem, index) {
                    return _c("li", { key: index }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            orderItem.quantity + "x " + orderItem.productName
                          ) +
                          " "
                      ),
                    ])
                  }),
                  0
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }