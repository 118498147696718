<template>
  <div>
    <b-table
      :key="tableKey"
      show-empty
      responsive
      class="bordered table-tooltip"
      :empty-text="$t('Aguarde...')"
      style="min-width: 400px; margin-top: 10px"
      :fields="fields"
      :items="items"
    />
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import { mapState } from 'vuex'

export default {
  components: { BTable },
  mixins: [],
  props: {
    orderId: {
      type: Number,
      required: true,
      default: null,
    },
  },

  data() {
    return {
      fetching: false,
      tableKey: 0,
      // items: [],
    }
  },

  computed: {
    ...mapState('pages/sale/deliveryDashboard', ['orderItems']),
    fields() {
      return [
        {
          label: this.$t('Produto'),
          key: 'productName',
          tdClass: 'text-left',
          formatter: (value, index, item) => `${item.skuId} - ${item.productName}`,
        },
        {
          label: this.$t('Qtd.'),
          key: 'quantity',
          tdClass: 'text-center',
          thStyle: 'width: 100px',
          formatter: value => this.$options.filters.number(value),
        },
      ]
    },
    items() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.tableKey += 1
      return this.orderItems[this.orderId] || []
    },
  },

  async mounted() {
    // await this.getItems()
    // try {
    //   console.log('Getting items')
    //   this.fetching = true
    //   this.items = (await this.$http.get(`api/sales/${this.orderId}/items`)).data
    // } catch (error) {
    //   this.showError({ error })
    // } finally {
    //   this.fetching = false
    // }
  },

  methods: {
    async getItems() {
      if (!this.items.length) {
        try {
          this.fetching = true
          this.items = (await this.$http.get(`api/sales/${this.orderId}/items`)).data
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
