<template>
  <section class="p-1">
    <header class="pb-1 d-flex justify-content-between align-items-start">
      <div class="d-flex column align-items-center">
        <span class="brand-logo mr-1">
          <b-img
            id="logo"
            :src="style ? style.reducedHeaderImage : ''"
            alt="logo"
            fluid
            class="rounded"
          />
        </span>
        <h1 class="text-dark h1 mb-0">
          {{ $t('Delivery') }}
        </h1>
        <b-avatar
          size="40"
          icon="chat"
          variant="light-primary"
          badge
          class="badge-minimal ml-1"
          :badge-variant="chatWebSocketConnected ? 'success' : 'danger'"
          :title="$t('Indica o status da conexão com os chats')"
        />
        <br>
      </div>
      <div v-if="filter.storeId">
        <b-avatar
          size="40"
          icon="truck"
          variant="light-primary"
          badge
          class="badge-minimal ml-1"
          :badge-variant="deliveryWebSocketConnected ? 'success' : 'danger'"
          :title="$t('Indica o status da conexão com a atualização dos pedidos de delivery')"
        />
        &nbsp;
        
        <b-button
          variant="link-primary"
          :title="$t('Atualizar lista de pedidos')"
          @click="getData"
        >
          <b-icon
            icon="arrow-clockwise"
            font-scale="1"
          />
        </b-button>

        <b-button
          variant="link-primary"
          :title="$t('Testar som')"
          @click="testSound"
        >
          <b-icon
            icon="volume-up"
            font-scale="1"
          />
        </b-button>
      </div>

      <div style="min-width: 300px">
        <e-store-combo
          v-model="filter.storeId"
          :disabled="!ready"
          :required="false"
          :clearable="true"
          :placeholder="ready ? $t('Selecione') : $t('Aguarde...')"
          @input="getData"
        />
      </div>
    </header>

    <b-row>
      <b-col cols="12">
        <e-color-subtitled
          :colors="[
            { backgroundStyleColor: '#ffc107', title: $t('Pendente'), link: '#section_pending' },
            {
              backgroundStyleColor: '#b8c2cc',
              title: $t('Preparando'),
              link: '#section_preparing',
            },
            {
              backgroundStyleColor: '#008200',
              title: $t('Realizando entrega'),
              link: '#section_delivering',
            },
            {
              backgroundStyleColor: 'pink',
              title: $t('Entrega finalizada'),
              link: '#section_delivered',
            },
            {
              backgroundStyleColor: '#aeecfc',
              title: $t('Enviar Cupom Fiscal'),
            },
            {
              backgroundStyleColor: 'white',
              border: '4px solid red !important',
              title: $t('Chat pendente'),
            },
          ]"
        />
      </b-col>
    </b-row>

    <div
      v-if="!filter.storeId"
      class="text-center"
    >
      <span class="h2"> {{ needSearchMessage }}</span>
    </div>
    <div v-else>
      <delivery-section
        link="section_pending"
        :loading="fetching"
        :title-text="$t('Pedidos pendentes')"
        :delivery-list="orders"
        :empty-message="lastFetch ? $t('Sem pedidos') : needSearchMessage"
        @show-chat="showChatSidebar"
      />

      <delivery-section
        class="mt-3"
        link="section_preparing"
        :loading="fetching"
        :title-text="$t('Pedidos sendo preparados')"
        :delivery-list="preparingOrders"
        :empty-message="lastFetch ? $t('Sem pedidos sendo preparados') : needSearchMessage"
        @show-chat="showChatSidebar"
      />

      <delivery-section
        class="mt-3"
        link="section_delivering"
        :loading="fetching"
        :title-text="$t('Realizando entrega')"
        :delivery-list="deliveringOrders"
        :empty-message="lastFetch ? $t('Sem pedidos sendo entregues') : needSearchMessage"
        @show-chat="showChatSidebar"
      />

      <delivery-section
        class="mt-3"
        link="section_delivered"
        :loading="fetching"
        :title-text="$t('Entrega finalizada')"
        :title-instruction="
          $t(
            'Os pedidos sairão automaticamente desta seção após 6 horas da finalização da sua entrega'
          )
        "
        :delivery-list="deliveredOrders"
        :empty-message="lastFetch ? $t('Sem pedidos finalizados') : needSearchMessage"
        @show-chat="showChatSidebar"
      />
    </div>
    <chat-side-bar ref="chatSidebar" />
  </section>
</template>

<script>
import { BImg, BIcon, BButton, BRow, BCol, BAvatar } from 'bootstrap-vue'
import { app } from '@/mixins'
import { mapActions, mapGetters, mapState } from 'vuex'
import { EStoreCombo, EColorSubtitled } from '@/views/components'
import isElectron from 'is-electron'
import DeliverySection from './components/DeliverySection.vue'
import ChatSideBar from './components/ChatSideBar.vue'

// eslint-disable-next-line global-require
const alarmSound = new Audio(require('@/assets/audio/echope_alert.mp3'))

export default {
  components: {
    BImg,
    BIcon,
    BButton,
    EStoreCombo,
    DeliverySection,
    BRow,
    BCol,
    BAvatar,
    EColorSubtitled,
    ChatSideBar,
  },

  mixins: [app],

  data() {
    return {
      fetching: false,
      autoScroll: true,
      autoScrollIntervalId: null,
      previousCount: 0,
      orderStatus: {},
    }
  },

  computed: {
    ...mapState('pages/pdv/payBoxConfiguration', ['pdvGlobalFilters']),
    ...mapGetters('pages/sale/deliveryDashboard', [
      'orders',
      'deliveringOrders',
      'preparingOrders',
      'deliveredOrders',
      'hasUnansweredChats',
      'ready',
      'chatWebSocketConnected',
      'deliveryWebSocketConnected',
    ]),
    ...mapState('pages/sale/deliveryDashboard', ['filter', 'lastFetch']),
    ...mapState('app', ['chatApiSettings']),

    needSearchMessage() {
      return this.$t('Selecione a loja para listar os pedidos')
    },
  },

  watch: {
    orders(val) {
      if (this.fetching) {
        this.previousCount = val?.length
        val.forEach(order => {
          this.orderStatus[order.id] = 'notified'
        })
      } else {
        // eslint-disable-next-line no-lonely-if
        if (this.previousCount < val.length) {
          alarmSound.play()
          val
            .filter(order => Object.keys(this.orderStatus).indexOf(String(order.id)) < 0)
            .forEach(order => {
              this.orderStatus[order.id] = 'new'
              setInterval(() => {
                this.orderStatus[order.id] = 'notified'
              }, 10000)
            })
        }
        this.previousCount = val.length
      }
    },
    async ready(val) {
      if (val) {
        const identify = isElectron() ? await window.electronAPI.system.identifyPayboxRead() : null
        const payBoxPdvFilterStoreId = this.pdvGlobalFilters?.payBox?.store?.id || identify?.storeId
        if (payBoxPdvFilterStoreId && !this.filter.storeId) {
          console.debug(
            'auto-select store config to PDV current payBoxPdvFilterStoreId',
            payBoxPdvFilterStoreId
          )
          this.setFilter({
            ...this.filter,
            storeId: payBoxPdvFilterStoreId.toString(),
          })
          await this.getData()
        }
      }
    },
    hasUnansweredChats(val) {
      if (!val) {
        this.setLastTimeHasUnansweredChats(null)
      } else {
        this.setLastTimeHasUnansweredChats(Date.now())
      }
    },
  },

  async mounted() {
    await this.fetchDeliveryWebSocketConfig()
    await this.connectToChatWebSocket()
    await this.connectToDeliveryWebSocket()
  },

  methods: {
    ...mapActions('pages/sale/deliveryDashboard', [
      'fetchDeliveryOrders',
      'fetchDeliveryWebSocketConfig',
      'loadActiveChats',
      'connectToChatWebSocket',
      'connectToDeliveryWebSocket',
      'joinChatChannel',
      'joinDeliveryChannel',
      'sendChatMessage',
      'setLastTimeHasUnansweredChats',
      'setFilter',
    ]),

    async getData() {
      try {
        this.fetching = true
        await this.fetchDeliveryOrders()
        await this.joinChatChannel()
        await this.joinDeliveryChannel()
        this.loadActiveChats()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },

    testSound() {
      alarmSound.play()
    },
    scrollToBottom() {
      if (this.autoScroll) {
        const { content } = this.$refs
        const { scrollHeight } = content
        const { scrollTop } = content
        const { clientHeight } = content
        const remainingScroll = scrollHeight - (scrollTop + clientHeight)
        if (remainingScroll > 0) {
          content.scrollBy(0, 10)
        } else {
          clearInterval(this.autoScrollIntervalId)
          this.autoScrollIntervalId = null
          setTimeout(() => {
            // Scroll back to the top after 2 seconds
            content.scrollTo(0, 0)
            this.autoScrollIntervalId = setInterval(this.scrollToBottom, 50)
          }, 2000)
        }
      }
    },

    toggleAutoScroll() {
      if (this.autoScrollIntervalId) {
        clearInterval(this.autoScrollIntervalId)
        this.autoScrollIntervalId = null
        this.autoScroll = false
      } else {
        this.autoScrollIntervalId = setInterval(this.scrollToBottom, 50)
        this.autoScroll = true
      }
    },

    showChatSidebar(order) {
      console.log('showChat', order)
      this.$refs.chatSidebar.show(order)
    },
  },
}
</script>

<style lang="scss" scoped>
.brand-logo {
  box-sizing: border-box;
  width: 35px;
}
</style>
