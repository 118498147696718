<template>
  <b-row :id="link">
    <b-col md="12">
      <p class="h2">
        {{ titleText }}
        <e-instructions
          v-if="titleInstruction"
          :instruction="titleInstruction"
        />
        <b-badge
          v-if="deliveryList"
          variant="danger"
        >
          {{ deliveryList.length }}
        </b-badge>
      </p>
    </b-col>

    <b-col
      md="12"
      class="d-flex justify-content-start flex-wrap"
      style="gap: 10px"
    >
      <template v-if="loading">
        <div class="ml-3">
          <e-spinner />
        </div>
      </template>
      <template v-else>
        <template v-if="deliveryList && deliveryList.length > 0">
          <delivery-card
            v-for="(deliveryData, i) in deliveryList"
            :key="i"
            :data="deliveryData"
            @show-chat="$emit('show-chat', deliveryData)"
          />
        </template>
        <template v-else>
          <div>
            <p class="h3">
              {{ emptyMessage }}
            </p>
          </div>
        </template>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BBadge } from 'bootstrap-vue'
import { ESpinner, EInstructions } from '@/views/components'
import DeliveryCard from './DeliveryCard.vue'

export default {
  components: { BRow, BCol, BBadge, DeliveryCard, ESpinner, EInstructions },

  props: {
    titleText: {
      type: String,
      required: true,
    },
    titleInstruction: {
      type: String,
      required: false,
      default: null,
    },
    emptyMessage: {
      type: String,
      required: true,
    },
    deliveryList: {
      type: Array,
      required: true,
    },
    link: {
      type: String,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    label() {
      return this.titleText.split(' ').join('_')
    },
  },
}
</script>

<style lang="scss" scoped></style>
