<template>
  <div class="chat-container">
    <ChatSidebar />
    <ChatWindow
      @send-message="sendMessage"
      @message-deleted="deleteMessage"
      @message-updated="updateMessage"
    />
  </div>
</template>

<script>
import ChatSidebar from './ChatSidebar.vue'
import ChatWindow from './ChatWindow.vue'

export default {
  components: {
    ChatSidebar,
    ChatWindow,
  },
  props: {},
  data() {
    return {}
  },
  computed: {},
  methods: {
    sendMessage(message) {
      this.$emit('send-message', message)
    },
    deleteMessage(message) {
      this.$emit('message-deleted', message)
    },
    updateMessage(message) {
      this.$emit('message-updated', message)
    },
  },
}
</script>

<style scoped>
.chat-container {
  display: flex;
  height: calc(100vh - 110px);
  border: 1px solid #eaeaea;
  border-radius: 4px;
}
</style>
