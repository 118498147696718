var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "p-1" },
    [
      _c(
        "header",
        {
          staticClass: "pb-1 d-flex justify-content-between align-items-start",
        },
        [
          _c(
            "div",
            { staticClass: "d-flex column align-items-center" },
            [
              _c(
                "span",
                { staticClass: "brand-logo mr-1" },
                [
                  _c("b-img", {
                    staticClass: "rounded",
                    attrs: {
                      id: "logo",
                      src: _vm.style ? _vm.style.reducedHeaderImage : "",
                      alt: "logo",
                      fluid: "",
                    },
                  }),
                ],
                1
              ),
              _c("h1", { staticClass: "text-dark h1 mb-0" }, [
                _vm._v(" " + _vm._s(_vm.$t("Delivery")) + " "),
              ]),
              _c("b-avatar", {
                staticClass: "badge-minimal ml-1",
                attrs: {
                  size: "40",
                  icon: "chat",
                  variant: "light-primary",
                  badge: "",
                  "badge-variant": _vm.chatWebSocketConnected
                    ? "success"
                    : "danger",
                  title: _vm.$t("Indica o status da conexão com os chats"),
                },
              }),
              _c("br"),
            ],
            1
          ),
          _vm.filter.storeId
            ? _c(
                "div",
                [
                  _c("b-avatar", {
                    staticClass: "badge-minimal ml-1",
                    attrs: {
                      size: "40",
                      icon: "truck",
                      variant: "light-primary",
                      badge: "",
                      "badge-variant": _vm.deliveryWebSocketConnected
                        ? "success"
                        : "danger",
                      title: _vm.$t(
                        "Indica o status da conexão com a atualização dos pedidos de delivery"
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "link-primary",
                        title: _vm.$t("Atualizar lista de pedidos"),
                      },
                      on: { click: _vm.getData },
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "arrow-clockwise", "font-scale": "1" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "link-primary",
                        title: _vm.$t("Testar som"),
                      },
                      on: { click: _vm.testSound },
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "volume-up", "font-scale": "1" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { "min-width": "300px" } },
            [
              _c("e-store-combo", {
                attrs: {
                  disabled: !_vm.ready,
                  required: false,
                  clearable: true,
                  placeholder: _vm.ready
                    ? _vm.$t("Selecione")
                    : _vm.$t("Aguarde..."),
                },
                on: { input: _vm.getData },
                model: {
                  value: _vm.filter.storeId,
                  callback: function ($$v) {
                    _vm.$set(_vm.filter, "storeId", $$v)
                  },
                  expression: "filter.storeId",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c("e-color-subtitled", {
                attrs: {
                  colors: [
                    {
                      backgroundStyleColor: "#ffc107",
                      title: _vm.$t("Pendente"),
                      link: "#section_pending",
                    },
                    {
                      backgroundStyleColor: "#b8c2cc",
                      title: _vm.$t("Preparando"),
                      link: "#section_preparing",
                    },
                    {
                      backgroundStyleColor: "#008200",
                      title: _vm.$t("Realizando entrega"),
                      link: "#section_delivering",
                    },
                    {
                      backgroundStyleColor: "pink",
                      title: _vm.$t("Entrega finalizada"),
                      link: "#section_delivered",
                    },
                    {
                      backgroundStyleColor: "#aeecfc",
                      title: _vm.$t("Enviar Cupom Fiscal"),
                    },
                    {
                      backgroundStyleColor: "white",
                      border: "4px solid red !important",
                      title: _vm.$t("Chat pendente"),
                    },
                  ],
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.filter.storeId
        ? _c("div", { staticClass: "text-center" }, [
            _c("span", { staticClass: "h2" }, [
              _vm._v(" " + _vm._s(_vm.needSearchMessage)),
            ]),
          ])
        : _c(
            "div",
            [
              _c("delivery-section", {
                attrs: {
                  link: "section_pending",
                  loading: _vm.fetching,
                  "title-text": _vm.$t("Pedidos pendentes"),
                  "delivery-list": _vm.orders,
                  "empty-message": _vm.lastFetch
                    ? _vm.$t("Sem pedidos")
                    : _vm.needSearchMessage,
                },
                on: { "show-chat": _vm.showChatSidebar },
              }),
              _c("delivery-section", {
                staticClass: "mt-3",
                attrs: {
                  link: "section_preparing",
                  loading: _vm.fetching,
                  "title-text": _vm.$t("Pedidos sendo preparados"),
                  "delivery-list": _vm.preparingOrders,
                  "empty-message": _vm.lastFetch
                    ? _vm.$t("Sem pedidos sendo preparados")
                    : _vm.needSearchMessage,
                },
                on: { "show-chat": _vm.showChatSidebar },
              }),
              _c("delivery-section", {
                staticClass: "mt-3",
                attrs: {
                  link: "section_delivering",
                  loading: _vm.fetching,
                  "title-text": _vm.$t("Realizando entrega"),
                  "delivery-list": _vm.deliveringOrders,
                  "empty-message": _vm.lastFetch
                    ? _vm.$t("Sem pedidos sendo entregues")
                    : _vm.needSearchMessage,
                },
                on: { "show-chat": _vm.showChatSidebar },
              }),
              _c("delivery-section", {
                staticClass: "mt-3",
                attrs: {
                  link: "section_delivered",
                  loading: _vm.fetching,
                  "title-text": _vm.$t("Entrega finalizada"),
                  "title-instruction": _vm.$t(
                    "Os pedidos sairão automaticamente desta seção após 6 horas da finalização da sua entrega"
                  ),
                  "delivery-list": _vm.deliveredOrders,
                  "empty-message": _vm.lastFetch
                    ? _vm.$t("Sem pedidos finalizados")
                    : _vm.needSearchMessage,
                },
                on: { "show-chat": _vm.showChatSidebar },
              }),
            ],
            1
          ),
      _c("chat-side-bar", { ref: "chatSidebar" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }