var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "delivery-order-card p-1 rounded",
      class:
        ((_obj = {
          "pending-answer": _vm.data.hasChatPendingAnswer,
        }),
        (_obj[_vm.cardStatus.cardClass] = true),
        _obj),
      attrs: { id: "div_" + _vm.data.id },
    },
    [
      _c(
        "div",
        [
          _vm.data.isPaid
            ? _c("p", [
                _c(
                  "sup",
                  { staticClass: "pr-1" },
                  [
                    _c(
                      "b-badge",
                      {
                        staticStyle: { "font-size": "13px", width: "100%" },
                        attrs: { variant: "success" },
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.$t("Pago")) + " "),
                        _vm.data.isPaid &&
                        _vm.data.onlinePayment &&
                        _vm.data.onlinePayment.paymentModel === "PayOnline"
                          ? _c("b", [
                              _vm._v(
                                "(" +
                                  _vm._s(_vm.$t("enviar cupom fiscal")) +
                                  ")"
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "d-inline-block position-relative mr-1 cursor-pointer",
              on: {
                click: function ($event) {
                  return _vm.showChat(_vm.data)
                },
              },
            },
            [
              _c("b-icon", { attrs: { icon: "chat", "font-scale": "1.5" } }),
              _vm.data.totalUnreadMessages || _vm.data.totalMessages
                ? _c(
                    "b-badge",
                    {
                      staticClass:
                        "position-absolute top-0 start-100 translate-middle",
                      attrs: {
                        variant: _vm.data.totalUnreadMessages
                          ? "danger"
                          : "success",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.data.totalUnreadMessages ||
                              _vm.data.totalMessages
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("span", { staticClass: "title" }, [
            _vm._v(
              _vm._s(_vm.data.customer.name || "") +
                " (" +
                _vm._s(_vm.saleOriginLabel(_vm.data.origin)) +
                ")"
            ),
          ]),
          _c("p"),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { md: "6" } }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v("Agendado para:"),
                ]),
                _vm._v(" "),
                _c("br"),
                _c("span", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm._f("datetime")(_vm.data.deliveryDate))),
                ]),
              ]),
              _c("b-col", { attrs: { md: "6" } }, [
                _c("span", { staticClass: "title" }, [_vm._v("Criado em:")]),
                _vm._v(" "),
                _c("br"),
                _c("span", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm._f("datetime")(_vm.data.issueDate))),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-1" },
            [
              _c("b-col", { attrs: { md: "6" } }, [
                _c("span", { staticClass: "title" }, [_vm._v("N.º Venda:")]),
                _vm._v(" "),
                _c("br"),
                _c("span", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.data.id)),
                ]),
              ]),
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c("span", { staticClass: "title" }, [_vm._v("Total:")]),
                  _vm._v(" "),
                  _c("br"),
                  _c("span", { staticClass: "data" }, [
                    _vm._v(_vm._s(_vm._f("currency")(_vm.data.netValue))),
                  ]),
                  _c("e-button", {
                    staticClass: "ml-50",
                    staticStyle: { padding: "10px" },
                    attrs: {
                      id: "btn_view_items_" + _vm.data.id,
                      text: _vm.$t("Itens"),
                      variant: "secondary",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-1" },
            [
              _c("b-col", { attrs: { md: "12" } }, [
                _c("span", { staticClass: "title" }, [_vm._v("Endereço:")]),
                _vm._v(" "),
                _c("br"),
                _c("span", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.formatAddress(_vm.data.deliveryAddress))),
                ]),
              ]),
            ],
            1
          ),
          _vm.cardStatus.btnStatus.text
            ? _c(
                "div",
                { staticClass: "mt-1 mb-0" },
                [
                  _vm.cardStatus.btnStatus.show
                    ? _c("e-button", {
                        staticClass: "w-100",
                        attrs: {
                          size: "sm",
                          icon: _vm.cardStatus.btnStatus.icon,
                          text: _vm.cardStatus.btnStatus.text,
                        },
                        on: { click: _vm.onChangeDeliveryStatus },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("b-tooltip", {
        ref: "tooltip_" + _vm.data.id,
        staticClass: "transparent-tooltip",
        attrs: { target: "btn_view_items_" + _vm.data.id },
        on: { shown: _vm.loadTableData },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("delivery-products", {
                  ref: "deliveryProducts",
                  attrs: { "order-id": _vm.data.id },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }