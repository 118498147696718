var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chat-container" },
    [
      _c("ChatSidebar"),
      _c("ChatWindow", {
        on: {
          "send-message": _vm.sendMessage,
          "message-deleted": _vm.deleteMessage,
          "message-updated": _vm.updateMessage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }