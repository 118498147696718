<template>
  <div class="chat-sidebar">
    <vue-perfect-scrollbar
      v-if="activeChat"
      :settings="perfectScrollbarSettings"
    >
      <div>
        <p>
          <b>{{ $t('Cliente') }}:</b> {{ activeChat.customer.name }}
        </p>
        <p>
          <b>{{ $t('Id do Pedido') }}:</b> {{ activeChat.id }}
        </p>
        <p>
          <b>{{ $t('Realizado em') }}:</b> {{ activeChat.issueDate | datetime }}
        </p>
        <p>
          <b>{{ $t('Agendado para') }}:</b> {{ activeChat.deliveryDate | datetime }}
        </p>
        <p>
          <b>{{ $t('Valor') }}:</b> {{ activeChat.netValue | currency }}
        </p>
      </div>
      <div>
        <h5>Produtos</h5>
        <ul>
          <li
            v-for="(orderItem, index) in localOrderItems"
            :key="index"
          >
            {{ `${orderItem.quantity}x ${orderItem.productName}` }}
          </li>
        </ul>
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    VuePerfectScrollbar,
  },
  props: {},
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
    }
  },
  computed: {
    ...mapState('pages/sale/deliveryDashboard', ['activeChat', 'orderItems']),
    localOrderItems() {
      return this.orderItems[this.activeChat.id]
    },
  },
  methods: {},
}
</script>

<style scoped>
.chat-sidebar {
  width: 30%;
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #f7f2f2;
}
.chats,
.contacts {
  padding: 10px;
}
.chats ul,
.contacts ul {
  list-style: none;
  padding: 0;
}
.chats li,
.contacts li {
  padding: 10px;
  cursor: pointer;
}
.chats li:hover,
.contacts li:hover {
  background-color: #f0f0f0;
}
</style>
