var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "chat-window",
      on: { focus: _vm.handleWindowFocus, blur: _vm.handleWindowBlur },
    },
    [
      _vm.activeChat && _vm.activeChat.messages.length
        ? _c(
            "div",
            { staticClass: "chat-messages" },
            [
              _c(
                "vue-perfect-scrollbar",
                {
                  ref: "scrollRef",
                  staticClass: "chat-user-list-wrapper list-group scroll-area",
                  attrs: { settings: _vm.perfectScrollbarSettings },
                },
                _vm._l(_vm.activeChat.messages, function (message, index) {
                  return _c(
                    "div",
                    {
                      key: message.id,
                      staticClass: "chat-user-list-wrapper list-group",
                    },
                    [
                      _vm.shouldShowTimestamp(index)
                        ? _c("div", { staticClass: "timestamp" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("time")(message.created)) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          class: {
                            message: true,
                            "message-sent": message.userId === _vm.currentUser,
                            "message-received":
                              message.userId !== _vm.currentUser,
                          },
                          on: {
                            mouseover: function ($event) {
                              return _vm.showActions(index)
                            },
                            mouseleave: function ($event) {
                              return _vm.hideActions()
                            },
                          },
                        },
                        [
                          _vm.shouldShowUsername(index)
                            ? _c("div", [
                                _c("strong", [
                                  _vm._v(_vm._s(message.from) + ":"),
                                ]),
                                _c("br"),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "message-content" }, [
                            _vm.editingMessageIndex !== index
                              ? _c("div", { staticClass: "message-text" }, [
                                  _vm._v(" " + _vm._s(message.content) + " "),
                                ])
                              : _vm._e(),
                            message.userId === _vm.currentUser
                              ? _c("div", { staticClass: "message-status" }, [
                                  message.delivered || message.read
                                    ? _c(
                                        "span",
                                        {
                                          class: {
                                            "message-read": message.read,
                                          },
                                        },
                                        [_vm._v("✓✓")]
                                      )
                                    : _c("span", [_vm._v("✓")]),
                                ])
                              : _vm._e(),
                            _vm.editingMessageIndex === index
                              ? _c("div", { staticClass: "chat-form" }, [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.editingMessage,
                                        expression: "editingMessage",
                                      },
                                    ],
                                    attrs: {
                                      rows: "2",
                                      placeholder: "Digite sua mensagem...",
                                    },
                                    domProps: { value: _vm.editingMessage },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.editingMessage = $event.target.value
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    { on: { click: _vm.confirmMessageEdit } },
                                    [_vm._v(" Enviar ")]
                                  ),
                                  _c(
                                    "button",
                                    { on: { click: _vm.cancelMessageEdit } },
                                    [_vm._v(" Cancelar ")]
                                  ),
                                ])
                              : _vm._e(),
                            message.userId === _vm.currentUser &&
                            _vm.hoveredMessageIndex === index &&
                            _vm.editingMessageIndex !== index
                              ? _c(
                                  "div",
                                  { staticClass: "message-actions ml-1" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "mb-2",
                                        attrs: {
                                          size: "sm",
                                          title: _vm.$t("Editar mensagem"),
                                          variant: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.updateMessage(
                                              message,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            icon: "pencil",
                                            "aria-label": "Help",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "chat-messages",
              staticStyle: { "text-align": "center" },
            },
            [_vm._v(" Nenhuma mensagem foi enviada ")]
          ),
      _c("div", { staticClass: "chat-form" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.newMessage,
              expression: "newMessage",
            },
          ],
          attrs: { rows: "2", placeholder: "Digite sua mensagem..." },
          domProps: { value: _vm.newMessage },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.newMessage = $event.target.value
              },
              _vm.handleTyping,
            ],
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.sendMessage($event)
            },
          },
        }),
        _vm._v(" "),
        _c("button", { on: { click: _vm.sendMessage } }, [_vm._v(" Enviar ")]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }