<template>
  <div
    class="chat-window"
    @focus="handleWindowFocus"
    @blur="handleWindowBlur"
  >
    <div
      v-if="activeChat && activeChat.messages.length"
      class="chat-messages"
    >
      <vue-perfect-scrollbar
        ref="scrollRef"
        :settings="perfectScrollbarSettings"
        class="chat-user-list-wrapper list-group scroll-area"
      >
        <div
          v-for="(message, index) in activeChat.messages"
          :key="message.id"
          class="chat-user-list-wrapper list-group"
        >
          <div
            v-if="shouldShowTimestamp(index)"
            class="timestamp"
          >
            {{ message.created | time }}
          </div>
          <div
            :class="{
              message: true,
              'message-sent': message.userId === currentUser,
              'message-received': message.userId !== currentUser,
            }"
            @mouseover="showActions(index)"
            @mouseleave="hideActions()"
          >
            <div v-if="shouldShowUsername(index)">
              <strong>{{ message.from }}:</strong><br>
            </div>
            <div class="message-content">
              <div
                v-if="editingMessageIndex !== index"
                class="message-text"
              >
                {{ message.content }}
              </div>
              <div
                v-if="message.userId === currentUser"
                class="message-status"
              >
                <span
                  v-if="message.delivered || message.read"
                  :class="{ 'message-read': message.read }"
                >✓✓</span>
                <span v-else>✓</span>
              </div>
              <div
                v-if="editingMessageIndex === index"
                class="chat-form"
              >
                <textarea
                  v-model="editingMessage"
                  rows="2"
                  placeholder="Digite sua mensagem..."
                />
                <button @click="confirmMessageEdit">
                  Enviar
                </button>
                <button @click="cancelMessageEdit">
                  Cancelar
                </button>
              </div>
              <div
                v-if="
                  message.userId === currentUser &&
                    hoveredMessageIndex === index &&
                    editingMessageIndex !== index
                "
                class="message-actions ml-1"
              >
                <!-- <b-button
                  size="sm"
                  :title="$t('Apagar mensagem')"
                  variant="danger"
                  class="mb-2 mr-1"
                  @click="deleteMessage(message)"
                >
                  <b-icon
                    icon="trash"
                    aria-label="Help"
                  />
                </b-button> -->
                <b-button
                  size="sm"
                  :title="$t('Editar mensagem')"
                  variant="primary"
                  class="mb-2"
                  @click="updateMessage(message, index)"
                >
                  <b-icon
                    icon="pencil"
                    aria-label="Help"
                  />
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </vue-perfect-scrollbar>
    </div>
    <div
      v-else
      class="chat-messages"
      style="text-align: center"
    >
      Nenhuma mensagem foi enviada
    </div>
    <div class="chat-form">
      <textarea
        v-model="newMessage"
        rows="2"
        placeholder="Digite sua mensagem..."
        @input="handleTyping"
        @keyup.enter="sendMessage"
      />
      <button @click="sendMessage">
        Enviar
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BButton } from 'bootstrap-vue'
import { alerts } from '@/mixins'

export default {
  components: {
    VuePerfectScrollbar,
    BButton,
  },
  mixins: [alerts],
  props: {},
  data() {
    return {
      newMessage: '',
      editingMessage: null,
      hoveredMessageIndex: null,
      editingMessageIndex: null,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      typingTimeout: null,
      typingSent: false,
    }
  },

  computed: {
    ...mapState('pages/sale/deliveryDashboard', ['activeChat', 'webSocket']),
    ...mapState('auth', ['userData']),
    currentUser() {
      return this.userData.userId
    },
  },
  watch: {
    activeChat() {
      this.$nextTick(() => {
        this.scrollToBottom()
      })
    },
    'activeChat.messages': {
      handler() {
        console.log('activeChat.messages')
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.scrollToBottom()
    })
  },
  methods: {
    sendMessage() {
      if (this.newMessage.trim() !== '') {
        this.$emit('send-message', this.newMessage)
        this.newMessage = ''
      }
    },
    scrollToBottom() {
      const { scrollRef } = this.$refs
      if (scrollRef) {
        scrollRef.$el.scrollTop = scrollRef.$el.scrollHeight
      }
    },
    shouldShowTimestamp(index) {
      if (index === 0) return true
      const { messages } = this.activeChat
      if (messages.length < 2) return false
      const currentMessage = new Date(messages[index].created)
      const previousMessage = new Date(messages[index - 1].created)
      return currentMessage.getMinutes() !== previousMessage.getMinutes()
    },
    shouldShowUsername(index) {
      if (index === 0) return true
      const currentUser = this.activeChat.messages[index].from
      const previousUser = this.activeChat.messages[index - 1].from
      return currentUser !== previousUser
    },
    showActions(index) {
      this.hoveredMessageIndex = index
    },
    hideActions() {
      this.hoveredMessageIndex = null
    },
    async deleteMessage(message) {
      if (await this.confirm({ text: this.$t('Confirma a exclusão desta mensagem?') })) {
        this.$emit('message-deleted', message)
      }
    },
    updateMessage(message, messageIndex) {
      this.editingMessage = message.content
      this.editingMessageIndex = messageIndex
    },
    confirmMessageEdit() {
      const existingMessage = this.activeChat.messages[this.editingMessageIndex]
      existingMessage.content = this.editingMessage
      this.$emit('message-updated', existingMessage)
      this.editingMessage = null
      this.editingMessageIndex = null
    },
    cancelMessageEdit() {
      this.editingMessage = null
      this.editingMessageIndex = null
    },
    handleWindowFocus() {
      // Set the chat window as active
      this.$store.commit('setChatWindowActive', true)
      // Send WebSocket message indicating that the message has been read
      if (this.messageStatus === 'delivered') {
        this.$store.dispatch('sendMessageReadStatus', this.messageId)
      }
    },
    handleWindowBlur() {
      // Set the chat window as inactive
      this.$store.commit('setChatWindowActive', false)
    },
    handleTyping() {
      if (this.typingTimeout) {
        clearTimeout(this.typingTimeout)
      }
      if (!this.typingSent) {
        this.sendTypingEvent() // Send typing event
        this.typingSent = true // Track that typing event has been sent
      }
      this.typingTimeout = setTimeout(() => {
        this.typingSent = false // Reset typing event tracking after 1 second of no typing
      }, 1000)
    },
    sendTypingEvent() {
      const typingEvent = {
        action: 'typing',
        channelId: this.activeChat.channelId,
      }
      this.webSocket.chat.ws.send(JSON.stringify(typingEvent))
    },
  },
}
</script>

<style>
.chat-window {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
}

.chat-header {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #f8f9fa;
}

.chat-messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  max-width: 60%;
  word-wrap: break-word;
  word-break: break-all;
  transition: background-color 0.3s;
}

.message:hover {
  background-color: #f0f0f0;
}

.message-read {
  color: #53bdeb;
}

.message-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.message-status {
  margin-left: 5px;
  letter-spacing: -4px;
}

.message:hover .message-actions {
  display: block;
}

.message-actions {
  display: none;
}

.message:hover .message-actions {
  display: flex;
}

.message-sent {
  background-color: #daf8cb;
  align-self: flex-end;
}

.message-received {
  background-color: #f1f1f1;
  align-self: flex-start;
}

.chat-form {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #f8f9fa;
}

.chat-form textarea {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
}

.chat-form button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.chat-form button:hover {
  background-color: #0056b3;
}

.timestamp {
  text-align: center;
  margin: 5px 0;
  color: #888;
  font-size: 12px;
}
</style>
