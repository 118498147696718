var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { attrs: { id: _vm.link } },
    [
      _c("b-col", { attrs: { md: "12" } }, [
        _c(
          "p",
          { staticClass: "h2" },
          [
            _vm._v(" " + _vm._s(_vm.titleText) + " "),
            _vm.titleInstruction
              ? _c("e-instructions", {
                  attrs: { instruction: _vm.titleInstruction },
                })
              : _vm._e(),
            _vm.deliveryList
              ? _c("b-badge", { attrs: { variant: "danger" } }, [
                  _vm._v(" " + _vm._s(_vm.deliveryList.length) + " "),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "b-col",
        {
          staticClass: "d-flex justify-content-start flex-wrap",
          staticStyle: { gap: "10px" },
          attrs: { md: "12" },
        },
        [
          _vm.loading
            ? [_c("div", { staticClass: "ml-3" }, [_c("e-spinner")], 1)]
            : [
                _vm.deliveryList && _vm.deliveryList.length > 0
                  ? _vm._l(_vm.deliveryList, function (deliveryData, i) {
                      return _c("delivery-card", {
                        key: i,
                        attrs: { data: deliveryData },
                        on: {
                          "show-chat": function ($event) {
                            return _vm.$emit("show-chat", deliveryData)
                          },
                        },
                      })
                    })
                  : [
                      _c("div", [
                        _c("p", { staticClass: "h3" }, [
                          _vm._v(" " + _vm._s(_vm.emptyMessage) + " "),
                        ]),
                      ]),
                    ],
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }