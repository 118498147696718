<template>
  <e-sidebar
    id="sidebar-chat"
    ref="sidebar"
    :title="`${$t('Atendimento para o pedido')} #${activeChat ? activeChat.id : ''}`"
    :show="showSidebar"
    :fetching="fetching"
    :saving="saving"
    :hide-save-button="true"
    :show-footer="false"
    width="80%"
    @hidden="hide"
  >
    <template #content>
      <ChatContainer
        @send-message="sendMessage"
        @message-deleted="deleteMessage"
        @message-updated="updateMessage"
      />
    </template>
  </e-sidebar>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { nanoid } from 'nanoid'
import ESidebar from '@/views/components/ESidebar.vue'
import ChatContainer from './chat/ChatContainer.vue'

export default {
  components: { ESidebar, ChatContainer },

  props: {},

  data() {
    return {
      showSidebar: false,
      fetching: false,
      saving: false,
    }
  },

  computed: {
    ...mapState('pages/sale/deliveryDashboard', ['orderItems', 'activeChat']),
    ...mapState('auth', ['userData']),
  },

  mounted() {},

  methods: {
    ...mapActions('pages/sale/deliveryDashboard', [
      'loadOrderItems',
      'startChat',
      'quitChat',
      'sendChatMessage',
      'editChatMessage',
      'deleteChatMessage',
      'readUnreadMessages',
    ]),
    async show(item) {
      this.cleanSidebar()
      this.showSidebar = true

      if (item) {
        try {
          this.fetching = true
          await Promise.all([this.startChat(item), this.loadOrderItems(item.id)])
          this.readUnreadMessages()
        } finally {
          this.fetching = false
        }
      }
    },

    hide() {
      this.cleanSidebar()
      this.quitChat()
      this.showSidebar = false
    },

    sendMessage(message) {
      const fullMessage = {
        action: 'send',
        channelId: this.activeChat.channelId,
        messageId: nanoid(20),
        content: message,
        userId: this.userData.userId,
        from: this.userData.userName,
        created: Date.now(),
      }

      this.sendChatMessage(fullMessage)
    },

    deleteMessage(message) {
      const fullMessage = {
        action: 'delete',
        channelId: this.activeChat.channelId,
        messageId: message.messageId,
      }

      this.deleteChatMessage(fullMessage)
    },

    updateMessage(message) {
      const fullMessage = {
        action: 'update',
        channelId: this.activeChat.channelId,
        messageId: message.messageId,
        content: message.content,
      }

      this.editChatMessage(fullMessage)
    },

    cleanSidebar() {},
  },
}
</script>

<style lang="scss" scoped>
.text-bold {
  font-weight: 800;
}
</style>
