var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-sidebar", {
    ref: "sidebar",
    attrs: {
      id: "sidebar-chat",
      title:
        _vm.$t("Atendimento para o pedido") +
        " #" +
        (_vm.activeChat ? _vm.activeChat.id : ""),
      show: _vm.showSidebar,
      fetching: _vm.fetching,
      saving: _vm.saving,
      "hide-save-button": true,
      "show-footer": false,
      width: "80%",
    },
    on: { hidden: _vm.hide },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("ChatContainer", {
              on: {
                "send-message": _vm.sendMessage,
                "message-deleted": _vm.deleteMessage,
                "message-updated": _vm.updateMessage,
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }