var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-table", {
        key: _vm.tableKey,
        staticClass: "bordered table-tooltip",
        staticStyle: { "min-width": "400px", "margin-top": "10px" },
        attrs: {
          "show-empty": "",
          responsive: "",
          "empty-text": _vm.$t("Aguarde..."),
          fields: _vm.fields,
          items: _vm.items,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }