<template>
  <div
    :id="`div_${data.id}`"
    class="delivery-order-card p-1 rounded"
    :class="{
      'pending-answer': data.hasChatPendingAnswer,
      [cardStatus.cardClass]: true,
    }"
  >
    <div>
      <p v-if="data.isPaid">
        <sup class="pr-1">
          <b-badge
            style="font-size: 13px; width: 100%"
            variant="success"
          >
            {{ $t('Pago') }}
            <b
              v-if="
                data.isPaid && data.onlinePayment && data.onlinePayment.paymentModel === 'PayOnline'
              "
            >({{ $t('enviar cupom fiscal') }})</b>
          </b-badge>
        </sup>
      </p>
      <div
        class="d-inline-block position-relative mr-1 cursor-pointer"
        @click="showChat(data)"
      >
        <b-icon
          icon="chat"
          font-scale="1.5"
        />
        <b-badge
          v-if="data.totalUnreadMessages || data.totalMessages"
          :variant="data.totalUnreadMessages ? 'danger' : 'success'"
          class="position-absolute top-0 start-100 translate-middle"
        >
          {{ data.totalUnreadMessages || data.totalMessages }}
        </b-badge>
      </div>
      <span class="title">{{ data.customer.name || '' }} ({{ saleOriginLabel(data.origin) }})</span>
      <p />
      <b-row>
        <b-col md="6">
          <span class="title">Agendado para:</span> <br>
          <span class="data">{{ data.deliveryDate | datetime }}</span>
        </b-col>
        <b-col md="6">
          <span class="title">Criado em:</span> <br>
          <span class="data">{{ data.issueDate | datetime }}</span>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="6">
          <span class="title">N.º Venda:</span> <br>
          <span class="data">{{ data.id }}</span>
        </b-col>
        <b-col md="6">
          <span class="title">Total:</span> <br>
          <span class="data">{{ data.netValue | currency }}</span>
          <e-button
            :id="`btn_view_items_${data.id}`"
            :text="$t('Itens')"
            variant="secondary"
            class="ml-50"
            style="padding: 10px"
          />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="12">
          <span class="title">Endereço:</span> <br>
          <span class="data">{{ formatAddress(data.deliveryAddress) }}</span>
        </b-col>
      </b-row>

      <div
        v-if="cardStatus.btnStatus.text"
        class="mt-1 mb-0"
      >
        <e-button
          v-if="cardStatus.btnStatus.show"
          class="w-100"
          size="sm"
          :icon="cardStatus.btnStatus.icon"
          :text="cardStatus.btnStatus.text"
          @click="onChangeDeliveryStatus"
        />
      </div>
    </div>
    <b-tooltip
      :ref="`tooltip_${data.id}`"
      :target="`btn_view_items_${data.id}`"
      class="transparent-tooltip"
      @shown="loadTableData"
    >
      <template #default>
        <delivery-products
          ref="deliveryProducts"
          :order-id="data.id"
        />
      </template>
    </b-tooltip>
  </div>
</template>

<script>
import { BBadge, BRow, BCol, BTooltip, BIcon } from 'bootstrap-vue'
import { address, deliveryDomain, statusTypes } from '@/mixins'
import EButton from '@/views/components/EButton.vue'
import { mapActions } from 'vuex'
import DeliveryProducts from './DeliveryProducts.vue'

const getInitialCardStatus = () => ({
  btnStatus: {
    show: false,
    text: '',
    icon: '',
  },
  cardClass: '',
})

export default {
  components: {
    BTooltip,
    BBadge,
    BRow,
    BCol,
    BIcon,
    EButton,
    DeliveryProducts,
  },
  mixins: [address, statusTypes, deliveryDomain],

  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        customer: {
          id: null,
          name: '',
        },
        issueDate: new Date().toISOString(),
        deliveryAddress: address.data().address,
        deliveryStatus: this.deliveryStatusEnum.PENDING,
        grossValue: 0,
        netValue: 0,
      }),
    },
  },

  data() {
    return {}
  },

  computed: {
    cardStatusOptions() {
      const { PENDING, IN_PROGRESS, PREPARING, COMPLETED } = this.deliveryStatusEnum
      return {
        PendingButPaid: {
          btnStatus: { show: true, text: this.$t('Preparar'), icon: 'box-seam' },
          cardClass: 'order-paid',
        },
        [PENDING]: {
          btnStatus: { show: true, text: this.$t('Preparar'), icon: 'box-seam' },
          cardClass: 'pending',
        },
        [PREPARING]: {
          btnStatus: { show: true, text: this.$t('Iniciar Entrega'), icon: 'truck' },
          cardClass: 'preparing',
        },
        [IN_PROGRESS]: {
          btnStatus: { show: true, text: this.$t('Finalizar Entrega'), icon: 'check-circle' },
          cardClass: 'delivering',
        },
        [COMPLETED]: {
          btnStatus: { show: true, text: null, icon: 'check-circle' },
          cardClass: 'delivered',
        },
      }
    },

    cardStatus() {
      const { PENDING } = this.deliveryStatusEnum

      return this.data?.isPaid &&
        this.data?.deliveryStatus === PENDING &&
        this.data?.onlinePayment?.paymentModel === 'PayOnline'
        ? this.cardStatusOptions.PendingButPaid
        : this.cardStatusOptions[this.data?.deliveryStatus] || getInitialCardStatus()
    },
  },

  mounted() {},

  methods: {
    ...mapActions('pages/sale/deliveryDashboard', ['loadOrderItems', 'setOrderDeliveryStatus']),
    async onChangeDeliveryStatus() {
      try {
        const newStatus = await this.mxChangeDeliveryStatus(this.data)
        this.setOrderDeliveryStatus(newStatus)
      } catch (error) {
        this.showError({ error })
      }
    },
    showChat(order) {
      this.$emit('show-chat', order)
    },
    loadTableData() {
      this.loadOrderItems(this.data.id)
    },
    saleOriginLabel(value) {
      switch (value) {
        case 'App':
          return this.$t('App')
        case 'Website':
          return 'Site'
        default:
          return 'ERP'
      }
    },
  },
}
</script>

<style lang="scss">
.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.top-0 {
  top: 0;
}

.start-100 {
  left: 100%;
}

.translate-middle {
  transform: translate(-50%, -50%);
}

.p-2 {
  padding: 0.25rem;
}

.b-badge {
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
}

.tooltip-inner {
  // background-color: white !important;
  max-width: 100%;
}

.table-tooltip td {
  background-color: white;
}

.delivery-order-card {
  flex: 1;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 1.125rem;
    font-weight: 600;

    span {
      font-weight: 800;
      padding-top: 5px;
      font-size: 1.4rem;
    }
  }

  .data {
    font-weight: bold;
  }

  &.order-paid {
    background-color: #aeecfc;
    border: 2px solid var(--gray);

    .title,
    .data {
      color: var(--dark);
    }
  }

  &.pending {
    background-color: #ffc107;
    border: 2px solid var(--yellow);

    .title,
    .data {
      color: var(--dark);
    }
  }

  &.preparing {
    background-color: #b8c2cc;
    border: 2px solid var(--gray);

    .title,
    .data {
      color: var(--dark);
    }
  }

  &.delivering {
    background-color: #008200;
    border: 2px solid var(--theme-color);

    .title,
    .data {
      color: var(--white);
    }
  }

  &.delivered {
    background-color: pink;
    border: 2px solid var(--gray);

    .title,
    .data {
      color: var(--black);
    }
  }

  @media (min-width: 700px) {
    min-width: 49.4%;
    max-width: 49.4%;
  }

  @media (min-width: 1300px) {
    min-width: 24.4%;
    max-width: 24.4%;
  }

  @media (min-width: 1900px) {
    min-width: 19.4%;
    max-width: 19.4%;
  }

  @media (min-width: 2200px) {
    min-width: 15.7%;
    max-width: 16.7%;
  }
}

.pending-answer.pending {
  animation: blinkPending 2s infinite;
}

.pending-answer.order-paid {
  animation: blinkOrderPaid 3s infinite;
}

.pending-answer.preparing {
  animation: blinkPreparing 2s infinite;
}

.pending-answer.delivering {
  animation: blinkDelivering 2s infinite;
}

.pending-answer.delivered {
  animation: blinkDelivered 2s infinite;
}

@keyframes blinkPending {
  0% {
    border: 7px solid red;
    background-color: #ffc107;
  }
  50% {
    border: 7px solid red;
    background-color: #f1e5c3;
  }
  100% {
    border: 7px solid red;
    background-color: #ffc107;
  }
}

@keyframes blinkOrderPaid {
  0% {
    border: 7px solid red;
    background-color: #aeecfc;
  }
  50% {
    border: 7px solid red;
    background-color: white;
  }
  100% {
    border: 7px solid red;
    background-color: #aeecfc;
  }
}

@keyframes blinkPreparing {
  0% {
    border: 7px solid red;
    background-color: #b8c2cc;
  }
  50% {
    border: 7px solid red;
    background-color: #f1f2f3;
  }
  100% {
    border: 7px solid red;
    background-color: #b8c2cc;
  }
}

@keyframes blinkDelivering {
  0% {
    border: 7px solid red;
    background-color: #008200;
  }
  50% {
    border: 7px solid red;
    background-color: #89e489;
  }
  100% {
    border: 7px solid red;
    background-color: #008200;
  }
}

@keyframes blinkDelivered {
  0% {
    border: 7px solid red;
    background-color: pink;
  }
  50% {
    border: 7px solid red;
    background-color: rgb(250, 135, 154);
  }
  100% {
    border: 7px solid red;
    background-color: pink;
  }
}
</style>
